<template>
  <div v-if="currentNode !== null">
    <LightGallery
      :images="contentLightGallery"
      :index="index"
      :disable-scroll="true"
      @close="index = null"/>
    <div class="main-page-content" ref="contentMain">
      <square-grid v-if="!isLoad()"
                   :background="'#ddd'"></square-grid>
      <transition name="fade" mode="out-in">
        <article class="article" v-if="isLoad()">
          <header data-aos="fade">
            <h1>{{ currentNode.title }}</h1>
          </header>
          <div class="article__image" v-if="currentNode.images.length > 0"
               data-aos="fade">
            <responsive-image
              :title="currentNode.title"
              :images="currentNode.images">
            </responsive-image>
          </div>
          <div class="article__content"
               data-aos="fade"
               :class="textLayout()"
               v-html="currentNode.body">
          </div>
          <div class="article__image-galleries" v-if="contentGalleries.length > 0">
            <masonry-wall :items="contentGalleries" :ssr-columns="1" :column-width="330" :gap="30">
              <template #default="{ item }">
                <div class="article__image-galleries__item" v-if="item !== false"
                     :key="item.id"
                     data-aos="fade"
                     @click="index = item.id">
                  <div class="image_field">
                    <responsive-image
                      :images="item.images">
                    </responsive-image>
                  </div>
                  <header>
                    <h4 class="article__image-galleries__title">{{ item.title }}</h4>
                    <div class="article__image-galleries__additional" v-html="item.additional"></div>
                  </header>
                </div>
                <div class="article__gallery-back" v-if="item === false">
                  <div class="article__gallery-back__btt" v-on:click="backToMain()">
                    <div class="content"></div>
                  </div>
                </div>
              </template>
            </masonry-wall>
          </div>
          <div class="article__attachments" v-if="contentAttachments.length > 0">
            <h4 class="article__attachments-title">{{$t('article.attachment')}}:</h4>
            <ul class="article__attachments-item-list">
              <li class="article__attachments-item"
                  v-for="(file, fileIndex) in contentAttachments" :key="fileIndex">
                <a :href="file.url" target="_blank" download>{{file.title}}</a>
                <div class="article__attachments-description"
                     v-html="file.body"></div>
              </li>
            </ul>
          </div>
          <webform/>
          <div class="article__back-to" v-if="!(contentGalleries.length > 0)">
            <div class="article__back-to__block">
              <div class="article__back-to__block__btt" v-on:click="backToMain()">
                <div class="content"></div>
              </div>
            </div>
          </div>
        </article>
      </transition>
    </div>
  </div>
</template>

<script>
    import { mapActions, mapState } from "vuex";
    import { i18n, defaultLocale } from '../main'
    import {SquareGrid} from 'vue-loading-spinner'
    import ResponsiveImage from "./partials/ResponsiveImage";
    import Webform from "./partials/webform";
    import LightGallery from "./partials/light-gallery";
    export default {
        name: "Page",
        components: {
            Webform,
            ResponsiveImage,
            SquareGrid,
            LightGallery
        },
        data: () => ({
            slug: String,
            display: false,
            index: null,
            contentLightGallery: []

        }),
        props: {
            nid: {
                type: Number,
                require: true,
            },
        },
        methods: {
            ...mapActions({
                getNodeId: 'fetchNodeByID',
                getNodeSlug: 'fetchNodeBySlug',
            }),
            isLoad() {
                return this.display && typeof this.currentNode !== "undefined" && typeof this.currentNode.title !== "undefined";
            },
            textLayout() {
                return (this.isLoad() && typeof this.currentNode.text_layout !== "undefined" && this.currentNode.text_layout !== false)
                    ? `text-${this.currentNode.text_layout}`
                    : '';
            },
            scrollToContent() {
                if ( typeof this.contentMain !== "undefined") {
                    this.$scrollTo( this.contentMain, 400, {easing: "ease-in-out"});
                }
            },
            elementFadeOut (nid, fade = 550) {
                this.display = false;
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(nid)
                    }, fade)
                })
            },
            backToMain() {
                if ( typeof this.contentMain !== "undefined") {
                    this.$scrollTo( this.contentMain, 400, {easing: "ease-in-out"});
                }
            },
        },
        computed: mapState({
            currentNode: state => state.currentNode,
            contentMain() {
                return this.$refs.contentMain
            },
            contentAttachments() {
                let files = [];
                if (this.display === true && typeof this.currentNode !== undefined) {
                    if (this.currentNode.attachments.length > 0) {
                        files = this.currentNode.attachments
                    }
                }
                return files
            },
            contentGalleries() {
                let gallery = [];
                this.contentLightGallery = [];
                if (this.display === true && typeof this.currentNode !== undefined) {
                    if (typeof this.currentNode.jury !== undefined || typeof this.currentNode.galleries !== undefined) {
                        if (this.currentNode.jury.length > 0) {
                            gallery = this.currentNode.jury
                        } else if (this.currentNode.galleries.length > 0) {
                            gallery = this.currentNode.galleries
                        }
                        if (gallery.length > 0 ) {
                            this.contentLightGallery = [...gallery]
                            gallery.push( false );
                        }
                    }
                }
                return gallery;
            },
        }),
        created() {
            if ( typeof this.$route.params !== "undefined") {
                this.slug = this.$route.params.slug;
            }
        },
        mounted() {
            this.elementFadeOut(this.slug).then(
                value => {
                    this.getNodeSlug( {
                        slug: value,
                        lang: localStorage.getItem('language') || defaultLocale }
                    ).then( () => {
                        this.scrollToContent();
                        this.display = true;
                    });
                }
            );
        },
        watch: {
            $route(to) {
                if ( typeof to.params !== "undefined") {
                    this.slug = to.params.slug;
                    this.scrollToContent();
                }
                if ( typeof this.nid !== "undefined") {
                    this.elementFadeOut(this.nid).then(
                        value => {
                            this.getNodeId( {
                                nid: value,
                                lang: i18n.locale
                            }).then( () => {
                                this.scrollToContent();
                                this.display = true
                            });
                        }
                    )
                }
            },
        }
    }
</script>
<style scoped lang="scss">
  @import '../styles/common';
  .main-page-content {
    position: relative;
    padding: 2rem 0;
    .spinner {
      transform: translate(-20px,-20px);
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 10;
    }
  }
  .article {
    margin-bottom: 5rem;
    header {
      margin: 1rem 0 3rem;
      h1 {
        margin: 0;
        font-weight: 800;
        text-align: center;
      }
    }
    &__image {
      margin: 1rem 0 2rem;
    }
    &__content {
      ::v-deep {
        p {
          margin-top: 0;
          margin-bottom: 1.5rem;
        }
        a {
          text-decoration: none;
          color: darken($gray, 20%);
          font-weight: 700;
          @include animation('btt-pulse 0.3s infinite alternate');
          &:hover {
            color: darken($gray, 30%);
          }
        }
      }
      text-align: justify;
      @include lg() {
        text-align: inherit;
        &.text-2-col {
          column-count: 2;
          column-gap: 2rem;
          break-inside: avoid;
        }
        &.text-3-col {
          column-count: 3;
          column-gap: 2rem;
          break-inside: avoid;
        }
      }

    }
    &__attachments {
      margin: 3rem 0 5rem;
      &-item-list {
        margin: 2rem 0;
        padding: 0;
        display: flex;
        gap: 2rem;
      }
      &-description {
        max-width: 14rem;
      }
      &-item {
        background-image: url('~@/assets/img/attachment.svg');
        background-repeat: no-repeat;
        background-position: left 0.5rem;
        padding-left: 2rem;
        a {
          text-decoration: none;
          font-weight: 700;
          color: darken(#c6c1bd, 20%);
          line-height: 110%;
          &:hover {
            color: darken(#c6c1bd, 30%);
          }
        }

        ::v-deep {
          p {
            margin: 0;
            line-height: 110%;
          }
        }
      }
    }
    &__image-jury,
    &__image-galleries {
      margin: 2rem 0;
      &__item {
        position: relative;
        .image_field {
          cursor: pointer;
          ::v-deep img {
            display: block;
            max-width: 100%;
            height: auto;
          }
        }
        header {
          margin: 0;
          position: absolute;
          bottom: 0.5rem;
          width: 100%;
          padding: 0 2rem 1rem;
          color: white;
        }
      }
      &__title {
        font-weight: 700;
        margin-bottom: 0.2rem;
      }
      &__additional,
      &__description {
        line-height: 130%;
        font-weight: 300;
        overflow: hidden;
        max-height: 5.1rem;
      }
    }
    &__jury-back,
    &__gallery-back {
      border: 2px solid $gray;
      position: relative;
      width: 100%;
      padding-top: 100%;
      &__btt {
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-decoration: none;
        @extend .btt-back-to;
      }
    }
    &__back-to {
      display: flex;
      justify-content: flex-end;
      margin: 2rem 0;
      &__block {
        position: relative;
        width: 33.33%;
        padding-top: 33.33%;
        border: 2px solid $gray;
        @include md() {
          width: 16.66%;
          padding-top: 16.66%;
        }
        &__btt {
          position: absolute;
          display: block;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          text-decoration: none;
          @extend .btt-back-to;
        }
      }
    }
  }
  .fade {
    &-enter {
    }

    &-leave {
    }

    &-enter-active {
      animation: fade-in 0.45s
    }

    &-leave-active {
      animation: fade-out 0.45s
    }

    @keyframes fade-in {
      from {
        opacity: 0.05
      }
      to {
        opacity: 1;
      }
    }
    @keyframes fade-out {
      from {
        opacity: 1;
      }
      to {
        opacity: 0.05;
      }
    }
  }
</style>
